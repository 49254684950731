
import React, { useState, useEffect } from "react"
import { loggedUser, printing } from  "../components/FirebaseAuth"
import { navigate } from "@reach/router"
import firebase from "../components/firebase"

function shuffle(array) 
{
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export async function LoadStateHouse (evt,userData,mode)
{
  let operationMode = 'return'
  if(mode == undefined)
  {
    operationMode = 'load'
  }
  if (operationMode == 'return')
  { return await LoadWithState(evt,userData,operationMode) }
  else
  {
    let retLoad = await LoadWithState(evt,userData,operationMode)
    //console.log("qwerttyyyyyyyyyyyy",retLoad)
    return retLoad
  }
}

async function LoadWithState (evt,userData,mode)
{ 
  let currentUser = loggedUser()
  let stateChosen = ""
  if(typeof(evt) != 'string')
  {
    evt.preventDefault();
    const form = evt.target;
    const data = new FormData(form);

    stateChosen = data.get("states")
    //console.log("selected state is ",stateChosen)
  }
  else
  {
    stateChosen = String(evt)
    //console.log("--------------------random state chosen is",stateChosen)
  }

  function getRandomInt(max) 
  {
    return Math.floor(Math.random() * max);
  }

  let retVal 
  let playedHomes  = []
  let houseMLS

  if (currentUser)
  {
    Object.entries(userData).map((f,index) =>
    {
      let params = String(f[0])
      let divider = params.search("_")
      let mlsParam = params.substring(0,divider)
      let stateParam = params.substring((divider+1))

      if(stateParam == stateChosen )
      {
        playedHomes.push(mlsParam)
      }
      //console.log("|", mlsParam, "|")
    })

    //console.log("Played Homes:", playedHomes)
  }
  
  //let db = firebase.firestore().collection('States').doc(stateChosen).collection('buffer')
  //.where('property_id','not-in',playedHomes).limit(1)
  /*
  await db.get().then( async (querySnapshot) => 
  {
    querySnapshot.forEach((doc) => 
    {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        //houseMLS.push(doc.data())
    });
  
  });
  */

  //console.log("RUNNING STATES FIREBASE CALL")
  let stateMLSlist = []
  let db = firebase.firestore().collection('States').doc(stateChosen)
  await db.get().then((doc) =>
  {
    if (doc.exists) 
    {
        stateMLSlist = doc.data()["list"]
        //console.log("Document mls list:", stateMLSlist, "size",stateMLSlist.length);
    } 
    else 
    {
        // doc.data() will be undefined in this case
        console.log("No state data!");
    }
  })
  .catch((error) => 
  {
      console.log("Error getting state data:", error);
  });

  let removeIDs = []
  for (let i = 0; i < stateMLSlist.length; i++) 
  {
    let mlsID = String(stateMLSlist[i].mls);
    for (let j = 0; j < playedHomes.length; j++) 
    {
      let playedID = String(playedHomes[j]);
      //console.log("comparing: ",mlsID,"|",playedID)
      if( mlsID.localeCompare(playedID) == 0)
      {
        //console.log("Deleted: ",mlsID)
        removeIDs.push(i)
      }
    }
  }
  //console.log("before",stateMLSlist)
  for (let i = removeIDs.length-1; i >= 0; i--)
  {
    stateMLSlist.splice(removeIDs[i],1);
  }
  //console.log("after",stateMLSlist)

  //console.log("Updated state MLS list",stateMLSlist, "size",stateMLSlist.length)
  if (stateMLSlist.length > 0)
  {
    let mixed = shuffle(stateMLSlist)

    let chosenHouse = mixed[0].mls

    //console.log("Selected house is",  chosenHouse)
    retVal = chosenHouse.concat("_").concat(stateChosen)
    if(mode == 'load')
    { 
      navigate(`/play?state=${stateChosen}&mls=${chosenHouse}`)
    }
    //window.location.href = `/play?state=${stateChosen}&mls=${chosenHouse}`
  }
  else
  {
    retVal = false
  }
  //page info was the data for the house when we queried for all the houses data
  //now we no longer do that so we cant provide that data unless we query for the house data here itself
  //which is pointless because the infrastructure for that exists on the play page.
  //navigate(`/play?state=${stateChosen}&mls=${chosenHouse}`, {state: { "pageInfo" : chosenHouse } })

  /*
  console.log("RUNNING STATES FIREBASE CALL")
  let db = firebase.firestore().collection('States').doc(stateChosen).collection('buffer') 

  await db.get().then( async (querySnapshot) => 
  {
    querySnapshot.forEach((doc) => 
    {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        houseMLS.push(doc.data())
    });

    //console.log(houseMLS)
    houseMLS = shuffle(houseMLS)
    //console.log("HOuse list after shuffling",houseMLS)
    //console.log("Houses Array length", houseMLS.length)
    let userData = {}

    if(currentUser)
    {
      console.log("RUNNING USERS FIREBASE CALL")
      await firebase.firestore().collection('Users').doc(currentUser.uid).get()
      .then((doc) => {
        if (doc.exists) 
        {
            //console.log("Document data:", doc.data());
            //doc.data()
            userData = doc.data()

        } 
        else 
        {
            // doc.data() will be undefined in this case
            console.log("No such user!");
        }
      }).catch((error) => {
          console.log("Error getting user:", error);
      });
    }
    let looping = true
    let selectHouse, keyName, pageInfo
    let numLoops = 0
    while (looping && (numLoops < houseMLS.length))
    {
      //console.log("+++")
      selectHouse = houseMLS[numLoops].property_id
      keyName = `${selectHouse}_${stateChosen}`
      pageInfo = houseMLS[numLoops]

      //console.log("----",numLoops, userData[keyName])
      if (userData[keyName] == undefined)
      {
        //console.log("ggggg",numLoops)
        looping = false
        break
      }
      else
      {
        ++numLoops
      }
    }

    if (numLoops >= houseMLS.length)
    {
      //console.log("played all of them", numLoops)
      let phrase = (String("Finished all ")).concat(String(stateChosen)," homes.")
      setFinished(phrase)
      retVal = false
    }
    else
    {
      //console.log("Selected house is", selectHouse, pageInfo)
      retVal = true
      
      navigate(`/play?state=${stateChosen}&mls=${selectHouse}`, {state: { "pageInfo" : pageInfo } })
      
    }

  });
  */

  return retVal
}

export async function LoadRandomHouse (userData,mode)
{

  //let stateArr = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", 
  //"IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", 
  //"NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]

  let stateArr = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", 
  "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", 
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]

  let max = stateArr.length - 1
  let id = Math.floor(Math.random() * max)

  let operationMode = 'return'
  if(mode == undefined)
  {
    operationMode = 'load'
  }

  let continuing = true
  let count = 0
  let stop = ''

  if (operationMode == 'load')
  {
    while (continuing & count<10) 
    {
      count++
      id = Math.floor(Math.random() * max)
      stop = await LoadWithState(stateArr[id],userData,operationMode) 
      //console.log("--------------------STOP IS ",stop)
      if (stop != false)
      {
        continuing = false
        break
      }
    }
    return stop
  }

  else if (operationMode == 'return')
  {
    continuing = true
    count = 0
    let firstHome, firstID
    while (continuing & count<10) 
    {
      count++
      id = Math.floor(Math.random() * max)
      stop = await LoadWithState(stateArr[id],userData,operationMode) 
      //console.log("--------------------STOP IS ",stop)
      if (stop != false)
      {
        firstID = id
        firstHome = stop
        continuing = false
        break
      }
    }

    stateArr.splice(firstID,1)

    max = stateArr.length - 1
    continuing = true
    count = 0
    let secondHome
    while (continuing & count<10) 
    {
      count++
      id = Math.floor(Math.random() * max)
      stop = await LoadWithState(stateArr[id],userData,operationMode) 
      //console.log("--------------------STOP IS ",stop)
      if (stop != false)
      {
        secondHome = stop
        continuing = false
        break
      }
    }

    return [firstHome,secondHome]
  }

}

